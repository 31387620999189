const siteUrl = 'https://donghoon-song.github.io'

module.exports = {
  title: `사진찍는 웹 개발자의 블로그`,
  description: `열심히 기록하려고 합니다.`,
  author: `Donghoon Song`,
  introduction: `사람들의 꿈을 이어주는 코멘토에서 일하고 있습니다.`,
  siteUrl: `https://donghoon-song.github.io`, // Your blog site url
  social: {
    twitter: `song_donghoon`, // Your Twitter account
    github: `donghoon-song`, // Your GitHub account
    medium: ``, // Your Medium account
    facebook: ``, // Your Facebook account
    linkedin: `donghoonsong`, // Your LinkedIn account
    instagram: `hoon_donghoon`, // Your Instagram account
  },
  icon: `content/assets/favicon.png`, // Add your favicon
  keywords: [`blog`],
  comment: {
    utterances: 'donghoon-song/hoon-blog-comments', // Your repository for archive comment
  },
  configs: {
    countOfInitialPost: 10, // Config your initial count of post
  },
  sponsor: {
    buyMeACoffeeId: '',
  },
  share: {
    facebookAppId: '', // Add facebookAppId for using facebook share feature v3.2
  },
  ga4: 'G-W9GEEKE99V',
  ad: 'pub-9259038358124016', // Add your google adsense publisherId `ca-pub-xxxxxxxxxx`
}
